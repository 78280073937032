import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Table from "react-bootstrap/Table";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import InvestorRelationsNavData from "../../../data/investor-relations/investor-relations-nav-data";
import Navigation from "../../../components/navigation/navigation";
import Icon from "../../../components/custom-widgets/icon";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const DividendHistory = () => {
  const title = "Dividend History";
  const description =
    "Start here to learn about WaFd Bank's dedication to supporting our investors by researching our dividend history.";
  const data = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/stock-dividend-history/hero-dividend-history-04282023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/stock-dividend-history/hero-dividend-history-04282023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/stock-dividend-history/hero-dividend-history-04282023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/stock-dividend-history/hero-dividend-history-04282023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/stock-dividend-history/hero-dividend-history-04282023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/stock-dividend-history/hero-dividend-history-04282023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/about-us/investor-relations/stock-dividend-history/hero-dividend-history-04282023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      dividendData: allWafdBankDividendPaymentHistoryCsv {
        nodes {
          Declared
          Record_Date
          Payable
          Amount
          Type
        }
      }
    }
  `);
  const dividendRowsInitial = data.dividendData.nodes;
  const dividendRows = [...dividendRowsInitial].reverse();

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/investor-relations/stock-dividend-history"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-dividend-history-04282023.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "stock-dividend-history-hero",
    ...getHeroImgVariables(data),
    altText: "Businesswoman working on laptop in office",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "A Rich Dividend History Since 1983"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/investor-relations",
      title: "Investor Relations"
    },
    {
      id: 3,
      active: true,
      title: "Dividend History"
    }
  ];

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <Navigation {...InvestorRelationsNavData} />
      <section className="container">
        <h1>Dividend History</h1>
        <h4>
          Original issue date: 11/17/82 @ $11.75 per share under the ticker symbol WFSL.
          <br />
          Cost basis for shares bought at original issue: $0.37 per share (as of last stock split 2/4/05).
        </h4>
        <a
          id="dividend-payment-history-pdf"
          href="/documents/wafd-bank-dividend-payment-history.pdf"
          target="_blank"
          title="Download PDF of Dividend Payment History"
          className="text-decoration-none"
        >
          <Icon name="file-pdf" class="mr-2" />
          Dividend Payment History
        </a>
      </section>
      <section className="container pt-0">
        <Table bordered responsive>
          <thead>
            <tr>
              {Object.keys(dividendRows[0]).map((columnName) => {
                return (
                  <Fragment key={columnName}>
                    <th>{columnName === "Record_Date" ? "Record Date" : columnName}</th>
                  </Fragment>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {dividendRows.map((dividendRow, index) => {
              return (
                <tr key={index}>
                  {Object.values(dividendRow).map((columnValue) => {
                    return (
                      <Fragment key={columnValue}>
                        <td>{columnValue}</td>
                      </Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default DividendHistory;
